// src/context/ThemeContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const tg = window.Telegram?.WebApp;
    const currentTheme = tg?.themeParams?.bg_color || "#ffffff"; // Default to light
    const isDark = tg?.themeParams?.bg_color === "#000000"; // Adjust based on Telegram's theme
    setTheme(isDark ? "dark" : "light");

    tg?.onEvent("themeChanged", () => {
      const newTheme = tg?.themeParams?.bg_color === "#000000" ? "dark" : "light";
      setTheme(newTheme);
    });
  }, []);

  return <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);