import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Content from "./components/Content";
import Footer from "./components/Footer";
import { ThemeProvider, useTheme } from "./context/ThemeContext";


const App = () => {
  const [user, setUser] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Open Orders");

 
// **************** INITIALIZATION OF TELEGRAM *********************** //
  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready(); 

    const userInfo = tg.initDataUnsafe?.user;
    if (userInfo) {  setUser(userInfo); } 
    else { console.error("Failed to authenticate Telegram user."); }
    tg.setBackgroundColor("bg_color");
  }, []);



  useEffect(() => {
    const fetchUserData = async () => {
      if (window.Telegram?.WebApp) {
        const initData = window.Telegram.WebApp.initDataUnsafe;

        if (initData?.user?.id) {
          const chatId = initData.user.id;

          try {
            const response = await axios.post("https://coinmarketsignals.io/api/check-user-status", { chat_id: chatId });
            setUser({
              first_name: initData.user.first_name,
              is_premium: response.data.is_premium,
            });
          } catch (error) {  console.error("Failed to fetch user data:", error); }
        } else {  console.warn("Chat ID not found in Telegram context."); }
      } else { console.error("Telegram WebApp not initialized.");}
    };
    fetchUserData();
  }, []);



  useEffect(() => {
    const tg = window.Telegram.WebApp;
    const initData = tg.initData;
    axios.post("http://92.113.21.60/validate", { initData })
      .then(response => console.log("User validated:", response.data))
      .catch(error => console.error("User validation failed:", error));
  }, []);


  return (
    <ThemeProvider>

    <div>
      <Header user={user} />
      <Navigation selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <Content selectedTab={selectedTab} />
      <Footer />
    </div>
    </ThemeProvider>
  );
};

const ThemedApp = () => {
  const { theme } = useTheme();

  const styles = {
    app: {
      backgroundColor: theme === "dark" ? "#1e1e1e" : "#ffffff",
      color: theme === "dark" ? "#ffffff" : "#000000",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
  };

  return (
    <div style={styles.app}>
      <Header />
      <Navigation />
      <Content />
      <Footer />
    </div>
  );
};

export default App;