// src/components/Content.js
import React from "react";
import OpenOrders from "../features/OpenOrders";
import Signals from "../features/Signals";

import { useTheme } from "../context/ThemeContext";

const Content = ({ selectedTab }) => {
  const { theme } = useTheme();

  const styles = {
    content: {
      padding: "20px",
      backgroundColor: theme === "dark" ? "#1e1e1e" : "#ffffff",
      color: theme === "dark" ? "#ffffff" : "#000000",
      minHeight: "calc(100vh - 100px)", // Adjust height to fill available space
    },
  };

  return (
    <div style={styles.content}>
      {selectedTab === "Open Orders" && <OpenOrders />}
      {selectedTab === "Latest Signals" && <Signals />}
      {selectedTab === "Settings" && <p>Settings Content Goes Here</p>}
    </div>
  );
};

export default Content;