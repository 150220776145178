// src/components/Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <p>© 2024 CoinMarket Signals</p>
    </footer>
  );
};

const styles = {
  footer: {
    textAlign: "center",
    padding: "10px",
    borderTop: "1px solid #dee2e6",
    backgroundColor: "#f8f9fa",
    fontSize: "0.9rem",
  },
};

export default Footer;