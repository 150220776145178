import React, { useState, useEffect } from "react";
import axios from "axios";
import tokensData from "../data/tokens_updated_prod.json"; // Import JSON file
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
  Grid,
  CircularProgress,
  Grid2,
  Chip,
  Box,
  TextField,
  Button,
} from "@mui/material";

const Signals = () => {
  const [token, setToken] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [signals, setSignals] = useState([]);
  const [error, setError] = useState("");
  const [chatId, setChatId] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState(""); // For the selected token
  const [loading, setLoading] = useState(false); // Loading state



  useEffect(() => {
    // Check if Telegram WebApp object is available
    if (window.Telegram?.WebApp) {
      const initData = window.Telegram.WebApp.initDataUnsafe;
      if (initData?.user?.id) {
        setChatId(initData.user.id); // Set the chat ID from the Telegram context
        
      } else {
        console.error("Chat ID not found in Telegram WebApp context.");
      }
    } else {
      console.error("Telegram WebApp not initialized.");
    }
  }, []);


  const fetchSignals = async () => {
    setError("");
    setSignals([]);

    if (!token || !timeframe) {
      setError("Please enter a token and timeframe.");
      return;
    }

    try {
      setLoading(true); // Set loading to true
      const premiumResponse = await axios.post("https://coinmarketsignals.io/api/check-user-status", { chat_id: chatId, });
  
      if (!premiumResponse.data.is_premium) {
        setError("This feature is available only for premium users. Upgrade to access all signals!");
        return;
      }
  
      const response = await axios.get("https://coinmarketsignals.io/api/last-signals", { params: { token, timeframe }, });
  
      if (response.data.error) { setError(response.data.error); } 
      else if (response.data.length === 0) { setSignals([]); } 
      else {
        setSignals(response.data);
        setSelectedSymbol(formattedToken.replace("USDT", ""));
      }
    } catch (err) { setError("Failed to fetch signals. Please try again."); }
  
    const formattedToken = token.toUpperCase().endsWith("USDT") ? token.toUpperCase() : `${token.toUpperCase()}USDT`;

    try {
      const response = await axios.get("https://coinmarketsignals.io/api/last-signals", {
        params: { token: formattedToken, timeframe },
      });

      if (response.data.error) { setError(response.data.error); } 
      else if (response.data.length === 0) {  setSignals([]); } 
      else {
        setSignals(response.data);
        setSelectedSymbol(formattedToken.replace("USDT", ""));
      }
    } catch (err) { setError("Failed to fetch signals. Please try again.");}
    finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const getTokenData = (symbol) => tokensData.find((item) => item.baseAsset === symbol);


  return (
    <div style={styles.container}>
      <Typography style={{fontSize:'1.2em', fontWeight: '600', paddingBottom:'20px'}} gutterBottom>
        Latest Signals
      </Typography>
      
      <div style={styles.form}>
      <Grid container spacing={3} >
        <Grid item md={12} style={{width: '100%'}} >
        <TextField
          label="Token (e.g., BTC)"
          variant="outlined"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          fullWidth
        />
        </Grid>
        <Grid item md={12}  style={{width: '100%'}} >
        <TextField
          label="Timeframe (e.g., 1H)"
          variant="outlined"
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
          fullWidth
        />
        </Grid>
        <Grid item md={12} style={{width: '100%'}}>
        <Button variant="contained" color="primary" fullWidth onClick={fetchSignals}>
        {loading ? <CircularProgress size={24} color="inherit" /> : "Fetch Signals"}
        </Button>
        </Grid>
        </Grid>
      </div>
      
      {error && <Typography color="error">{error}</Typography>}
    
      <Grid container spacing={3} style={styles.grid}>
        {signals.map((signal, index) => {
          const tokenData = getTokenData(selectedSymbol.toUpperCase());
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card style={styles.card}>
                {/* Header with Background and Logo */}
                <CardHeader
                  style={{
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                  }}
                  avatar={
                    <Avatar
                      src={tokenData?.logoURL}
                      alt={tokenData?.baseAsset || token.toUpperCase()}
                      style={styles.avatar}
                    />
                  }
                />
                {/* Title and Details */}
                <CardContent>
                  <Typography style={{fontSize: '1em', fontWeight: "600"}}>{signal.signal_type}</Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    <i>{signal.timestamp}</i>
                  </Typography>
                  <Box style={styles.detailsBox}>
                    <Typography variant="body1" style={styles.price}>
                      Price: ${signal.price.toFixed(2)}
                    </Typography>
                   
                  </Box>
                  {/* Take Profit and Stop Loss Section */}
                  <Typography variant="subtitle2" style={styles.subtitle}>
                    Take Profit and Stop Loss
                  </Typography>
                  <Box style={styles.chipsBox}>
                    {signal.take_profit_1 && (
                      <Chip label={`TP1: ${signal.take_profit_1}`} style={styles.takeProfitChip} />
                    )}
                    {signal.take_profit_2 && (
                      <Chip label={`TP2: ${signal.take_profit_2}`} style={styles.takeProfitChip} />
                    )}
                    {signal.take_profit_3 && (
                      <Chip label={`TP3: ${signal.take_profit_3}`} style={styles.takeProfitChip} />
                    )}
                    {signal.stop_loss_1 && (
                      <Chip label={`SL1: ${signal.stop_loss_1}`} style={styles.stopLossChip} />
                    )}
                    {signal.stop_loss_2 && (
                      <Chip label={`SL2: ${signal.stop_loss_2}`} style={styles.stopLossChip} />
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    textAlign: "left",
    
  },
  form: {
    marginBottom: "20px",
    marginLeft: 'auto',
    marginRight: 'auto',
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    width: "100%",
    
  },
  grid: {
    marginTop: "20px",
  },
  card: {
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  avatar: {
    width: "60px",
    height: "60px",
    margin: "0 auto",
  },
  detailsBox: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  price: {
    fontWeight: "bold",
    color: "#333",
  },
  change: {
    color: "#666",
  },
  subtitle: {
    marginTop: "15px",
    marginBottom: "10px",
    color: "#555",
  },
  chipsBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  takeProfitChip: {
    backgroundColor: "#2ecc71",
    color: "#fff",
  },
  stopLossChip: {
    backgroundColor: "#e74c3c",
    color: "#fff",
  },
};

export default Signals;