import React from "react";

const Header = ({ user }) => {
  return (
    <header style={styles.header}>
      <h1 style={styles.title}>Ben's Coin Market Signals</h1>
      {user && (
        <div style={styles.userInfo}>
          <span>
            {user.first_name}
          </span>
          <span style={user.is_premium ? styles.premiumBadge : styles.freeBadge}>
            {user.is_premium ? "Premium" : "Free"}
          </span>
        </div>
      )}
    </header>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#f8f9fa",
    borderBottom: "1px solid #dee2e6",
  },
  title: {
    margin: 0,
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "1rem",
    fontWeight: "500",
  },
  premiumBadge: {
    backgroundColor: "#28a745", // Green for Premium
    color: "#fff",
    borderRadius: "4px",
    padding: "2px 6px",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  freeBadge: {
    backgroundColor: "#dc3545", // Red for Free
    color: "#fff",
    borderRadius: "4px",
    padding: "2px 6px",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
};

export default Header;