import React, { useState } from "react";
import axios from "axios";
import {
  Card,
  Avatar,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Button,
  Box,
  LinearProgress,
} from "@mui/material";
import tokensData from "../data/tokens_updated_prod.json"; // Import JSON file

const BINANCE_OI_URL = "https://fapi.binance.com/futures/data/globalLongShortAccountRatio";

const OpenOrders = () => {
  const [symbol, setSymbol] = useState("");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(""); // For the selected token
  const [loading, setLoading] = useState(false); // Loading state



  const fetchLongShortRatio = async (token) => {
    try {
      setLoading(true);
      setError(null); // Reset any previous errors
      setData(null); // Clear old data
      const params = {
        symbol: token.toUpperCase(),
        period: "5m",
        limit: 1,
      };
      const response = await axios.get(BINANCE_OI_URL, { params });

      if (response.status === 200 && response.data.length > 0) {
        const latestData = response.data[0];
        setData({
          longShortRatio: parseFloat(latestData.longShortRatio),
          longAccount: parseFloat(latestData.longAccount) * 100,
          shortAccount: parseFloat(latestData.shortAccount) * 100,
        });
        setSelectedSymbol(token); // Update selected token after data is fetched

      } else {
        setError("No data available for this token.");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    }
    finally{ setLoading(false)}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!symbol.endsWith("USDT")) {
      fetchLongShortRatio(`${symbol}USDT`);
    } else {
      fetchLongShortRatio(symbol);
    }
  };

  const getTokenData = (symbol) => tokensData.find((item) => item.baseAsset === symbol);


  return (
    <Box style={styles.container}>
      <Typography style={{fontSize:'1.2em', fontWeight: '600', paddingBottom:'20px'}} gutterBottom>
      Open Orders
      </Typography>
      <div style={styles.form}>
      <form onSubmit={handleSubmit} style={styles.form}>
      <Grid container spacing={3}>
      
      <Grid item md={12} style={{width: '100%'}} >
        <TextField
          label="Token (e.g., BTC)"
          variant="outlined"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          fullWidth
          style={styles.input}
        />
        </Grid>
        <Grid item md={12} style={{width: '100%'}} >
        <Button variant="contained" color="primary" type="submit" style={styles.button}>
        {loading ? <CircularProgress size={24} color="inherit" /> : "Get Data"}
        </Button>
        </Grid>
      
      </Grid>
      </form>
      </div>
      {error && (
        <Typography color="error" style={styles.error}>
          {error}
        </Typography>
      )}
      {data && (
      

        <Card style={styles.card}>
        
        <CardHeader
            style={{
              backgroundColor: "#f5f5f5",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
            avatar={
              <Avatar
                src={getTokenData(selectedSymbol.toUpperCase().replace("USDT", ""))?.logoURL || ""}
                alt={getTokenData(selectedSymbol.toUpperCase().replace("USDT", ""))?.baseAsset || selectedSymbol.toUpperCase()}
                style={styles.avatar}
              />
            }
            title={
              <Box style={styles.headerDetails}>
                <Typography variant="h6" style={styles.tokenName}>
                  {getTokenData(selectedSymbol.toUpperCase().replace("USDT", ""))?.baseAsset || selectedSymbol.toUpperCase()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Long/Short Ratio: {data.longShortRatio.toFixed(2)}
                </Typography>
              </Box>
            }
          />
          <CardContent>
          
            <Box style={styles.progressContainer}>
  {/* Long Value on Left */}
  <Typography variant="body2" color="textSecondary" style={{ textAlign: "left" }}>
    Long: {data.longAccount.toFixed(2)}%
  </Typography>

  {/* Progress Bar */}
  <LinearProgress
    variant="determinate"
    value={data.longAccount}
    style={{
      ...styles.progressBar,
      backgroundColor: "#e74c3c", // Background for short percentage
    }}
    sx={{
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#2ecc71", // Green for long percentage
      },
    }}
  />

  {/* Short Value on Right */}
  <Typography variant="body2" color="textSecondary" style={{ textAlign: "right", marginLeft: "auto" }}>
    Short: {data.shortAccount.toFixed(2)}%
  </Typography>
    </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

const styles = {
  container: {
    padding: "20px",
    textAlign: "center",
  },
  form: {
    marginBottom: "20px",
    marginLeft: 'auto',
    marginRight: 'auto',
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    width: "100%",
    
  },
  input: {
    flex: 1,
    marginRight: "10px",
  },
  button: {
    height: "55px",
    width: "100%"
  },
  error: {
    marginTop: "10px",
    fontWeight: "bold",
  },
  card: {
    marginTop: "20px",
    textAlign: "left",
    
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  cardHeader: {
    backgroundColor: "#f5f5f5",
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  resultItem: {
    marginBottom: "10px",
  },
  progressContainer: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  progressBar: {
    height: "15px",
    borderRadius: "5px",
  },
};

export default OpenOrders;