// src/components/Navigation.js
import React from "react";

const Navigation = ({ selectedTab, setSelectedTab }) => {
  const tabs = ["Open Orders", "Latest Signals"];

  return (
    <nav style={styles.nav}>
      {tabs.map((tab) => (
        <button
          key={tab}
          style={{
            ...styles.tab,
            ...(selectedTab === tab ? styles.activeTab : {}),
          }}
          onClick={() => setSelectedTab(tab)}
        >
          {tab}
        </button>
      ))}
    </nav>
  );
};

const styles = {
  nav: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    padding: "10px",
    borderBottom: "1px solid #dee2e6",
  },
  tab: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
    padding: "5px 10px",
    borderBottom: "2px solid transparent",
  },
  activeTab: {
    borderBottom: "2px solid #007bff",
    color: "#007bff",
  },
};

export default Navigation;